import "./index.css"
import React from "react";
import Header from "./blocks/Header/Header";
import Footer from "./blocks/Footer/Footer";
import Main from "./blocks/Main/Main";
import Contact from "./blocks/Contact/Contact";
import { LanguageProvider } from './components/LanguageContext/LanguageContext';

function App() {
  return (
    <LanguageProvider>
      <div className="root-wrapper">
        <Header></Header>
        <Main></Main>
        <Contact></Contact>
        <Footer></Footer>
      </div>
    </LanguageProvider>
  );
}

export default App;
