import React, { useContext } from 'react';
import styles from "./Header.module.css";
import { LanguageContext } from '../../components/LanguageContext/LanguageContext';
import { translations } from '../../components/LanguageJSON/LanguageJSON';
import Logo from "../../Logo";

// Main loop slider gallery

import gallery_loop_1 from "../../assets/img/gallery/1.png";
import gallery_loop_2 from "../../assets/img/gallery/2.png";
import gallery_loop_3 from "../../assets/img/gallery/3.png";
import gallery_loop_4 from "../../assets/img/gallery/4.png";
import gallery_loop_5 from "../../assets/img/gallery/5.png";
import gallery_loop_6 from "../../assets/img/gallery/6.png";
import gallery_loop_7 from "../../assets/img/gallery/7.png";
import gallery_loop_8 from "../../assets/img/gallery/8.png";
import gallery_loop_9 from "../../assets/img/gallery/9.png";
import gallery_loop_10 from "../../assets/img/gallery/10.png";
import gallery_loop_11 from "../../assets/img/gallery/11.png";
import gallery_loop_12 from "../../assets/img/gallery/12.png";
import gallery_loop_13 from "../../assets/img/gallery/13.png";
import gallery_loop_14 from "../../assets/img/gallery/14.png";
import gallery_loop_15 from "../../assets/img/gallery/15.png";
import gallery_loop_16 from "../../assets/img/gallery/16.png";
import gallery_loop_17 from "../../assets/img/gallery/17.png";
import gallery_loop_18 from "../../assets/img/gallery/18.png";
import gallery_loop_19 from "../../assets/img/gallery/19.png";
import gallery_loop_20 from "../../assets/img/gallery/20.png";
import gallery_loop_21 from "../../assets/img/gallery/21.png";

// End main loop slider gallery


const TAGS = [
    gallery_loop_1,
    gallery_loop_2,
    gallery_loop_3,
    gallery_loop_4,
    gallery_loop_5,
    gallery_loop_6,
    gallery_loop_7,
    gallery_loop_8,
    gallery_loop_9,
    gallery_loop_10,
    gallery_loop_11,
    gallery_loop_12,
    gallery_loop_13,
    gallery_loop_14,
    gallery_loop_15,
    gallery_loop_16,
    gallery_loop_17,
    gallery_loop_18,
    gallery_loop_19,
    gallery_loop_20,
    gallery_loop_21,
];
const DURATION = 120000;
const ROWS = 3;
const TAGS_PER_ROW = 10;

const random = (min, max) => Math.floor(Math.random() * (max - min)) + min;
const shuffle = (arr) => [...arr].sort( () => .5 - Math.random() );

const InfiniteLoopSlider = ({children, duration, reverse = false}) => {
  return (
    <div className='loop-slider' style={{
        '--duration': `${duration}ms`,
        '--direction': reverse ? 'reverse' : 'normal'
      }}>
      <div className='inner'>
        {children}
        {children}
      </div>
    </div>
  );
};

const Tag = ({ src }) => (
  <div className='tag'>
    <img src={src} alt="Portfolio SAV Studio"></img>
  </div>
);

export default function Header(props){
    const svg_language = {"__html": `<svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M10 0C4.5 0 0 4.5 0 10s4.5 10 10 10 10-4.5 10-10S15.5 0 10 0Zm6.9 6H14c-.3-1.3-.8-2.4-1.4-3.6 1.8.7 3.4 1.9 4.3 3.6ZM10 2c.8 1.2 1.5 2.5 1.9 4H8.1c.4-1.4 1.1-2.8 1.9-4ZM2.3 12c-.2-.6-.3-1.3-.3-2s.1-1.4.3-2h3.4c-.1.7-.1 1.3-.1 2s.1 1.3.1 2H2.3Zm.8 2H6c.3 1.3.8 2.4 1.4 3.6-1.8-.7-3.4-1.9-4.3-3.6ZM6 6H3.1c1-1.7 2.5-2.9 4.3-3.6C6.8 3.6 6.3 4.7 6 6Zm4 12c-.8-1.2-1.5-2.5-1.9-4h3.8c-.4 1.4-1.1 2.8-1.9 4Zm2.3-6H7.7c-.1-.7-.2-1.3-.2-2s.1-1.3.2-2h4.7c.1.7.2 1.3.2 2s-.2 1.3-.3 2Zm.3 5.6c.6-1.1 1.1-2.3 1.4-3.6h2.9c-.9 1.7-2.5 2.9-4.3 3.6Zm1.8-5.6c.1-.7.1-1.3.1-2s-.1-1.3-.1-2h3.4c.2.6.3 1.3.3 2s-.1 1.4-.3 2h-3.4Z" fill="#969696" fill-rule="evenodd" className="fill-000000"></path></svg>`};
    const { currentLang, setCurrentLang } = useContext(LanguageContext);
    const handleLanguageChange = (lang) => {
        setCurrentLang(lang);
    };
    const language = translations[currentLang]['header'];
    const chank_lang_en = currentLang == "en" ? styles.active_lang : "";
    const chank_lang_ua = currentLang == "ua" ? styles.active_lang : "";
    return (
        <header className={styles.header + " dark-theme d-flex flex-column"}>
            <div className={styles.header_content + " w-100 d-flex flex-column align-items-center"}>
                <div className={styles.header_blur}>
                    <div className={"container " + styles.sav_content_box}>
                        <div className="col-2 d-flex align-items-center">
                            <div className={styles.logo}>
                                <Logo width={50} height={50}></Logo>
                            </div>
                        </div>
                        
                        <div class="col justify-content-center align-items-center btn_ancor">
                            <a class="link_ancor" href="#about_us">About</a>
                            <a class="link_ancor" href="#services">Services</a>
                            <a class="link_ancor" href="#feedback">Feedback</a>
                            <a class="link_ancor" href="#contacts">Contacts</a>
                        </div>

                        <div className="col-2 d-flex justify-content-end p-0 m-0">
                            <div className="row ">
                                <div className="col p-0 m-0">
                                    <button className={styles.language}>
                                        <div dangerouslySetInnerHTML={svg_language} />
                                        <div className={styles.list_language}>
                                            <button className={styles.flag + " " + styles.flag_en + " " + chank_lang_en} onClick={() => handleLanguageChange('en')}></button>
                                            <button className={styles.flag + " " + styles.flag_ua + " " + chank_lang_ua} onClick={() => handleLanguageChange('ua')}></button>
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"container " + styles.header_box}>
                    <div className={styles.content_abs}>

                        <div className='tag-list'>
                            {[...new Array(ROWS)].map((_, i) => (
                            <InfiniteLoopSlider key={i} duration={random(DURATION - 5000, DURATION + 5000)} reverse={i % 2}>
                                {shuffle(TAGS).slice(0, TAGS_PER_ROW).map(tag => (
                                <Tag src={tag} key={tag}/>
                                ))}
                            </InfiniteLoopSlider>
                            ))}
                            <div className='fade'/>
                        </div>
                        
                        <div className={ styles.header_general}>
                            <Logo width={90} height={90}></Logo>
                            <span className={ styles.line_header}></span>
                            <h1>{language.head.title}</h1>
                            <p className={ styles.dsc}>{language.head.dsc}</p>
                            <div className={styles.btn_line}>
                                <div>
                                        <a href="#contacts" className={[styles.order_bg]}>
                                        <span>
                                            <svg version="1.1" viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg"><g><g><path class="st0" fill="#EC6E62" d="M44.7,16l-5.2-8.9c-0.4-0.6-1.1-1-1.8-1h-35C1.8,6.1,1,6.9,1,7.8v18.5c0,1,0.8,1.7,1.7,1.7h35    c0.7,0,1.4-0.4,1.8-1l5.2-8.9C45.1,17.5,45.1,16.7,44.7,16z M38.6,19.1c-1.1,0-2-0.9-2-2s0.9-2,2-2c1.1,0,2,0.9,2,2    S39.7,19.1,38.6,19.1z"/></g><g><path class="st1" fill="#656766" d="M28.9,43.9c-0.3,0-0.7,0-1-0.1c-1.3-0.2-2.5-0.8-3.3-1.8c-1.3-1.6-1.7-4.2-1-6.5c0.7-1.9,2.1-3.6,4-4.5    c1.4-0.7,3.1-1.1,5.2-1.1c0.8,0,1.6,0,2.4,0c0.9,0,1.7,0.1,2.5,0c2.1-0.1,4.1-0.5,6.1-1.2c0.9-0.4,2-0.8,2.6-1.8    c0.8-1.2,0.9-3.1,0.2-4.7c-0.6-1.4-1.7-2.5-3.2-3.2c-1.4-0.6-3-0.8-4.5-0.9c-0.5,0-1-0.5-0.9-1c0-0.5,0.5-1,1-0.9    c1.7,0.1,3.5,0.3,5.2,1c1.9,0.9,3.4,2.4,4.2,4.2c0.9,2.2,0.8,4.8-0.4,6.6c-0.9,1.4-2.4,2.1-3.5,2.5c-2.2,0.8-4.4,1.3-6.7,1.3    c-0.9,0-1.8,0-2.7,0c-0.8,0-1.5-0.1-2.3,0c-1.8,0-3.2,0.3-4.4,0.9c-1.4,0.7-2.5,2-3,3.4c-0.5,1.6-0.3,3.5,0.6,4.6    c0.6,0.8,1.5,1,2.1,1.1c1.9,0.3,3.9-0.7,5.2-2.5c0.3-0.4,0.9-0.6,1.4-0.3s0.6,0.9,0.3,1.4C33.5,42.6,31.2,43.9,28.9,43.9z"/></g><g><path class="st2" fill="#fff" d="M7.8,14.6v1.9H11v1.1H7.8v2.9H6.6v-7.1h4.7l0,1.1H7.8z"/><path class="st2" fill="#fff" d="M18.4,15.8c0,1.2-0.5,1.9-1.5,2.2l1.9,2.6h-1.5l-1.7-2.4H14v2.4h-1.2v-7.1h2.6c1.1,0,1.9,0.2,2.3,0.5    C18.2,14.4,18.4,15,18.4,15.8z M16.8,16.8c0.2-0.2,0.4-0.5,0.4-1c0-0.5-0.1-0.8-0.4-1c-0.3-0.2-0.7-0.3-1.3-0.3H14v2.5h1.5    C16.1,17.1,16.6,17,16.8,16.8z"/><path class="st2" fill="#fff" d="M25.1,13.5v1.1h-3.8v1.9h3.4v1.1h-3.4v1.9h3.9v1.1h-5.1v-7.1H25.1z"/><path class="st2" fill="#fff" d="M31.8,13.5v1.1H28v1.9h3.4v1.1H28v1.9h3.9v1.1h-5.1v-7.1H31.8z"/></g></g></svg>
                                        </span>
                                        {language.head.button_appointment}
                                    </a>
                                </div>
                                <div>
                                    <a href="#about_us" className={[styles.order]}>
                                    <svg style={{width: "20px", height: "20px", transform: "translateY(-1px)", marginRight: "10px"}} version="1.1" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24"><path d="M12 18.5c-3.6 0-6.5-2.9-6.5-6.5S8.4 5.5 12 5.5s6.5 2.9 6.5 6.5-2.9 6.5-6.5 6.5z" fill="none" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" class="stroke-000000"></path><path d="M12 14.2c-1.2 0-2.2-1-2.2-2.2 0-1.2 1-2.2 2.2-2.2 1.2 0 2.2 1 2.2 2.2 0 1.2-1 2.2-2.2 2.2z" fill="none" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" class="stroke-000000"></path><path d="M22.9 13c-6.2 7.3-15.5 7.3-21.7 0-.5-.6-.5-1.5 0-2.1 6.2-7.3 15.5-7.3 21.7 0 .5.6.5 1.5 0 2.1z" fill="none" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" class="stroke-000000"></path></svg>
                                    {language.head.button_about_us}
                                    </a>    
                                </div>
                            </div>
                        
                        </div>
                    
                    </div>
                </div>
            </div>
            
        </header>
    );
}