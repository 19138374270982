import React, { createContext, useState } from 'react';
import { translations } from '../LanguageJSON/LanguageJSON';

export const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [currentLang, setCurrentLang] = useState('en');

  return (
    <LanguageContext.Provider value={{ currentLang, setCurrentLang, translations }}>
      {children}
    </LanguageContext.Provider>
  );
};