import React from "react";
import styles from "./SteepsCreate.module.css";

// {"title": "", "description": ""}
const SteepsCreate = (props)=>{
    return (
        <div className={styles.grid_map}>
            <div className={styles.container_grid}>
                <h1 className="text-center">{ props.title }</h1>
                <div className={styles.map + " mt-5 d-flex flex-column align-items-center"}>
                    <span className={"line_map " + styles.line}></span>

                    {props.array.map((item, idx)=>{
                        const inx_check = idx != 0 && "mt-3";
                        return (<div key={idx} className={styles.map_steep + " " + inx_check  + " w-100 d-flex"}>
                            <div className="row w-100 d-flex row-cols-2">
                                <div className="col-2">
                                    <div className={styles.circle}></div>
                                </div>
                                <div className="col-10">
                                    <h3>{item.title}</h3>
                                    <p className={styles.description}>
                                        {item.description}
                                    </p>
                                </div>
                            </div>
                        </div> )
                    })}

                </div>   
            </div>
        </div>
    )
}

export default SteepsCreate