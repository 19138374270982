import React, { useState, useRef, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faArrowRight, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
library.add(faStar, faArrowRight, faArrowLeft);

const FeedbackSlider = (props) => {
  const [showCustomButtons, setShowCustomButtons] = useState(true);
  const [currentSlide, setCurrentSlide] = useState(0);
  const sliderRef = useRef();

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 2000) {
        setShowCustomButtons(false);
      } else {
        setShowCustomButtons(true);
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1919,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 1399,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: false,
        },
      },
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: false,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: false,
        },
      },
    ],
    beforeChange: (current, next) => {
      setCurrentSlide(next);
    },
    afterChange: (current) => {
      setCurrentSlide(current);
    },
  };

  return (
    <>
      <Slider {...settings} ref={sliderRef}>
        {props.array.map((item, idx) => (
          <div key={idx} className="p-2">
            <div className="card">
              <div className="card-body">
                <div className="d-flex align-items-center gap-2">
                  <div>
                    <img alt="Image placeholder" src={ item.img } className="avatar-feedback rounded-circle" />
                  </div>
                  <div className="pl-3">
                    <h5 className="h6 mb-0">{ item.name }</h5>
                    <small className="d-block text-muted">{ item.company }</small>
                  </div>
                </div>
                <p className="mt-4 lh-180 overflow-hidden">{ item.message }</p>
                <span className="static-rating static-rating-sm">
                  <FontAwesomeIcon icon="star" className="voted" />
                  <FontAwesomeIcon icon="star" className="voted" />
                  <FontAwesomeIcon icon="star" className="voted" />
                  <FontAwesomeIcon icon="star" className="voted" />
                  <FontAwesomeIcon icon="star" className="voted" />
                </span>
              </div>
            </div>
          </div>
        ))}
      </Slider>

      {showCustomButtons && (
        <div className="row mt-4 d-flex">
          <div className="col d-flex justify-content-end">
            <button
              className={`btn-slider custom-slider-btn custom-slider-prev`}
              //  ${
              //   currentSlide === 0 ? "disabled" : ""
              // }`}
              onClick={() => sliderRef.current.slickPrev()}
            >
              <FontAwesomeIcon icon="arrow-left" />
            </button>
          </div>
          <div className="col d-flex justify-content-start">
            <button
              className={`btn-slider custom-slider-btn custom-slider-prev`}
              // ${
              //   currentSlide + settings.slidesToScroll >= props.array.length ? "disabled" : ""
              // }`}
              onClick={() => sliderRef.current.slickNext()}
            >
              <FontAwesomeIcon icon="arrow-right" />
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default FeedbackSlider;
