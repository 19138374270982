import React, { useContext } from "react";
import { useTrail, animated } from "react-spring";
import { useInView } from "react-intersection-observer";
import { LanguageContext } from '../../components/LanguageContext/LanguageContext';
import { translations } from '../../components/LanguageJSON/LanguageJSON';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faArrowRight, faArrowLeft, faLightbulb, faPerson, faHandsHoldingCircle, faChevronDown} from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
library.add(faStar, faArrowRight, faArrowLeft, faLightbulb, faPerson, faHandsHoldingCircle, faChevronDown);

function AnimatedCard({ item, idx }) {
    const [ref, inView] = useInView({
        triggerOnce: true,
        rootMargin: "-100px 0px",
    });

    const trail = useTrail(1, {
        opacity: inView ? 1 : 0,
        transform: inView ? "translateY(0)" : "translateY(20px)",
        config: { mass: 5, tension: 1500, friction: 500 },
        delay: 200 * idx,
    });

    const AnimatedDiv = animated.div;

    return (
        <AnimatedDiv style={{ ...trail[0] }} className="p-2 col-lg-4">
            <div ref={ref} className="card h-100 px-3">
                <div className="card-body py-5">
                    <div className="d-flex align-items-center">
                        <div>
                            <div className={"icon text-white rounded-circle icon-shape " + item.style }>
                                <FontAwesomeIcon icon={item.icon} />
                            </div>
                        </div>
                        <div className="icon-text pl-4">
                            <h5 className="mb-0">{ item.title }</h5>
                        </div>
                    </div>
                    <p className="mt-4 mb-0">{ item.dsc }</p>
                </div>
            </div>
        </AnimatedDiv>
    );
}

export default function AboutStudio() {

    const { currentLang, setCurrentLang } = useContext(LanguageContext);
    const language = translations[currentLang]['main'];

    const array = [
        {"icon": "lightbulb", "style": "bg-gradient-primary shadow-primary", "title": language.about_us.boxed['0'].title, "dsc": language.about_us.boxed['0'].dsc},
        {"icon": "person", "style": "bg-gradient-warning shadow-warning", "title": language.about_us.boxed['1'].title, "dsc": language.about_us.boxed['1'].dsc },
        {"icon": "hands-holding-circle", "style": "bg-gradient-info shadow-info", "title": language.about_us.boxed['2'].title, "dsc": language.about_us.boxed['2'].dsc},
    ];

    return (
        <div className="row">
            {array.map((item, idx) => (
                <AnimatedCard key={idx} item={item} idx={idx} />
            ))}
        </div>
    );
}
