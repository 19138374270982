import React from "react";
import { useTrail, animated } from "react-spring";
import { useInView } from "react-intersection-observer";

function AnimatedBox({ item, idx, props }) {
  const [ref, inView] = useInView({
    triggerOnce: true,
    rootMargin: "100px 0px",
  });

  const trail = useTrail(1, {
    opacity: inView ? 1 : 0,
    transform: inView ? "translateY(0)" : "translateY(20px)",
    config: { mass: 5, tension: 1500, friction: 500 },
    delay: 200 * idx,
  });

  const svgContent = { __html: item.img };

  return (
    <div ref={ref}>
      <animated.div style={{ ...trail[0], width: '100%' }}>
        <div className={"col d-flex justify-content-center rounded-2 position-relative"}>
        {props.decor ? (
                  <div className={`hexagon-container decor-${idx}`}>
                    <div className="hexagon"></div>
                  </div>
            ) : null}
            <div
              className={
                "row shadow-3 rounded-2 d-flex justify-content-center align-items-center w-100 " +
                (props.style ? props.style : "")
              }
            >
            <div
              className={
                "col h-100 rounded-4 gap-1 d-flex flex-column justify-content-center align-items-center " +
                props.paddingTop
              }
            >
              <div dangerouslySetInnerHTML={svgContent} />
              <h3>{item.title}</h3>
              <p>{item.dsc}</p>
            </div>
          </div>
        </div>
      </animated.div>
    </div>
  );
}

export default function BoxInfo(props) {
  return (
    <div className={props.responsive}>
      {props.array.map((item, idx) => (
        <AnimatedBox key={idx} item={item} idx={idx} props={props} />
      ))}
    </div>
  );
}
