export default function Logo({width, height}){
    return (
        <svg fill="#fff" width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 484.1 224.47">
            <g>
                <path d="m47.61,5.64l63.96,100c.94,1.46.72,3.38-.53,4.59L1.11,217.72c-2.35,2.3-.72,6.28,2.56,6.28h17.85c.97,0,1.89-.38,2.58-1.06l86.66-85.85c2.83-2.8,7.46.28,5.98,3.97l-31.6,78.37c-.97,2.41.8,5.04,3.4,5.04,4.89,0,9.79-.02,14.68-.03,1.5,0,2.85-.93,3.4-2.33l39.32-100.32c.43-1.09.31-2.31-.32-3.3L86.4,24.93c-1.54-2.44.21-5.62,3.09-5.62l93.94-.12c1.51,0,2.86-.93,3.41-2.33l4.62-11.86c.94-2.4-.84-4.99-3.41-4.99H50.7c-2.89,0-4.65,3.2-3.09,5.64Z"/>
                <path d="m210.55.24h12.3c2.6,0,4.38,2.61,3.44,5.03l-84.25,216.62c-.55,1.42-1.92,2.35-3.44,2.35h-13.13c-2.6,0-4.39-2.62-3.44-5.04L207.11,2.58c.55-1.41,1.92-2.34,3.44-2.34Z"/>
                <path d="m245.18.24h12.29c2.6,0,4.39,2.61,3.44,5.03l-84.24,216.61c-.55,1.42-1.92,2.36-3.44,2.36h-13.12c-2.6,0-4.39-2.62-3.44-5.05L241.74,2.58c.56-1.41,1.92-2.34,3.44-2.34Z"/>
                <path d="m280.49.24h12.28c2.6,0,4.39,2.62,3.45,5.04l-84.24,216.6c-.55,1.42-1.92,2.36-3.45,2.36h-13.11c-2.61,0-4.4-2.63-3.44-5.05L277.05,2.58c.56-1.42,1.92-2.35,3.44-2.35Z"/>
                <path d="m468.27.24h12c2.69,0,4.54,2.71,3.57,5.22l-84.14,216.34c-.57,1.47-1.99,2.44-3.57,2.44h-12.84c-2.7,0-4.55-2.72-3.56-5.23L464.7,2.66c.58-1.47,1.99-2.43,3.56-2.43Z"/>
                <path d="m383.53,193.68L308.48,2.58c-.56-1.41-1.92-2.34-3.44-2.34h-12.29c-2.6,0-4.39,2.61-3.44,5.04l71.78,184.57c.37.94,1.1,1.69,2.03,2.07,5.19,2.14,10.36,4.33,15.54,6.52,3.01,1.28,6.08-1.71,4.88-4.75Z"/>
                <path d="m369.55,211.6l4.08,10.48c.51,1.3,1.76,2.16,3.16,2.16h13.78c2.39,0,4.03-2.41,3.16-4.63l-1.38-3.53c-.33-.85-1-1.53-1.84-1.88-5.5-2.3-10.98-4.63-16.47-6.95-2.76-1.17-5.56,1.56-4.48,4.35Z"/>
                <polygon points="358.06 128.81 365.6 148.01 220.24 148.07 227.68 128.91 358.06 128.81"/>
            </g>
        </svg>
    )
}