import React, { useContext } from "react";
import styles from "./Footer.module.css";
import Logo from "../../Logo";

export default function Footer(props){
    
    return (
        <footer className={styles.footer}>
            <div className={styles.bg_box}></div>
            <div className={styles.footer_content + " container"}>

                <div className="row d-flex flex-column row-cols-1 h-100 justify-content-between">
                    <div className="col">
                        <div className={styles.copy}>
                            <div className="row py-3 py-md-0 row-cols-1 row-cols-md-2 d-flex w-100">
                                <div style={{gap: "10px", flexWrap: "wrap"}} className="col d-flex justify-content-center align-items-center justify-content-md-start">
                                    <div className={styles.adaptive_line}>
                                        <Logo width={40} height={40}></Logo>
                                        
                                        <span style={{margin: "0 10px"}}>Copyright © 2023 SAV Studio</span>
                                        <span style={{margin: "0 10px"}}>|</span>
                                        <svg style={{ width: "12px", transform: "translateY(-1px)", marginRight: 5 + "px"}} viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 512 512"><path d="M86.4 480h339.2c12.3 0 22.4-9.9 22.4-22.1V246c0-12.2-10-22-22.4-22H404v-30.9c0-41.5-16.2-87.6-42.6-115.4-26.3-27.8-64-45.7-105.3-45.7h-.2c-41.3 0-79 17.9-105.3 45.6C124.2 105.4 108 151.5 108 193v31H86.4C74 224 64 233.9 64 246v211.9c0 12.2 10 22.1 22.4 22.1zM161 193.1c0-27.3 9.9-61.1 28.1-80.3v-.3C206.7 93.9 231 83 255.9 83h.2c24.9 0 49.2 10.9 66.8 29.5v.2l-.1.1c18.3 19.2 28.1 53 28.1 80.3V224H161v-30.9z" fill="#fff" className="fill-000000"></path></svg><span>Secured with SSL</span>
                                    </div>
                                </div>
                                    <div className="col d-flex justify-content-center justify-content-md-end align-items-center">
                                        <a className="cursor-pointer" target="_blank" href="http://savbase.se/?v=true">Developer & Designer Oleksandr Shypilo</a>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </footer>
    );
}